import { render, staticRenderFns } from "./1-Intro2.vue?vue&type=template&id=0f1e01b8"
import script from "./1-Intro2.vue?vue&type=script&lang=js"
export * from "./1-Intro2.vue?vue&type=script&lang=js"
import style0 from "./1-Intro2.vue?vue&type=style&index=0&id=0f1e01b8&prod&lang=scss"


/* normalize component */
import normalizer from "!../../node_modules/_vue-loader@15.11.1@vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports