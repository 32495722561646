<template>
  <div class="intro-wrap">
    <headNav active="1"></headNav>
    <div class="intro-title">
      <div class="w1200 tal">
        <div class="title">
          <img src="../assets/intro-title.png" style="width: 372px;" alt="" v-if="$i18n.locale == 'zh'" />
          <img src="../assets/intro-titleEn.png" style="width: 372px;" alt="" v-else />
        </div>
        <p class="cmain fs16 pb32" v-html="$t('introductory.title1')">
          <!-- {{$t('introductory.title1')}} -->
        </p>
        <p class="cmain fs16 pb32" v-html="$t('introductory.title2')">
          <!-- {{$t('introductory.title2')}} -->
        </p>

        <!-- <p class="cmain fs16 pb32">
          {{$t('introductory.title3')}}
        </p> -->
      </div>
    </div>
    <video
      class="wd"
      controls
      src="https://c.vod.suzhousco.com/sv/52261218-17e47235ee6/52261218-17e47235ee6.mp4"
    >
      your browser does not support the video tag
    </video>
    <!-- <div class="pic">
      <a-carousel arrows>
        <div slot="prevArrow" class="custom-slick-arrow">left</div>
        <div slot="nextArrow" class="custom-slick-arrow">right</div>
        <div>
          <img src="../assets/intro-pic.jpg" alt="" style="width: 100%" />
        </div>
      </a-carousel>
    </div> -->
    <footNav />
  </div>
</template>

<script>
import headNav from "../components/header2.vue";
import footNav from "../components/footer2.vue";
export default {
  name: "App",
  components: { headNav, footNav },
  data() {
    return {};
  },
  mounted() {},
  methods: {}
};
</script>
<style lang="scss">
.intro-title {
  // height: 1100px;
  overflow: hidden;
  background: url("../assets/intro-title-bg.jpg") no-repeat 0 50%;
  background-size: 100% 100%;
  margin-top: -138px;
  padding-top: 380px;
  .title {
    padding-bottom: 60px;
  }
  p {
    line-height: 200%;
  }
}

.intro-wrap {
  .pic {
    height: 837px;
    overflow: hidden;
  }
}

/* For demo */
.intro-wrap,
.carousel-common {
  .ant-carousel .slick-prev,
  .ant-carousel .slick-next {
    width: 19px;
    height: 44px;
    z-index: 100;
    margin-top: -60px;
  }

  .slick-prev {
    left: 32px;
    background: url("../assets/left.png");
    &:hover {
      background: url("../assets/left.png");
    }
  }

  .slick-next {
    right: 32px;
    background: url("../assets/right2.png");
    &:hover {
      background: url("../assets/right2.png");
    }
  }
}
</style>
